import { useEffect, useState } from 'react';
import axios from 'axios';
import * as config from '../config';
import { getCookie, setCookie } from '@/lib/cookie';
import { env } from '@/env';
import { useLang } from '@/lib/hook';

const SOUTH_KOREA = 'South Korea';
const SOUTH_KOREA_IP_ACCESS_TOKEN_NAME = 'kor-ip-access';

export default function IPChecker() {
  const { currentLang } = useLang();
  const [country, setCountry] = useState('');

  const handleClickClose = (notToday?: boolean) => () => {
    if (notToday) {
      setCookie(SOUTH_KOREA_IP_ACCESS_TOKEN_NAME, 'true', {
        path: '/', // 모든 경로에 적용
        maxAge: 24 * 60 * 60, // 30일 유지
        ...(process.env.NODE_ENV === 'production' ? { domain: env.client.domain } : {}),
      });
    }
    setCountry('');
  };

  useEffect(() => {
    if (getCookie(SOUTH_KOREA_IP_ACCESS_TOKEN_NAME) === 'true') {
      return;
    }

    const fetchIp = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const clientCountry = response.data.country_name;

        setCountry(clientCountry);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };

    fetchIp();
  }, []);

  if (country === SOUTH_KOREA) {
    return (
      <div className="fixed top-0 right-0 bottom-0 left-0 bg-black/90 z-[999] backdrop:blur-md flex justify-center items-center">
        <div className="mx-2">
          <img
            src={`/images/${
              String(config.SERVICE_TYPE) === String(config.SERVICE_RACING)
                ? 'warning_upodium.png'
                : 'warning_up7bet.png'
            }`}
            alt=""
          />
          <div className="flex flex-row w-full justify-between bg-black/90 px-3 py-2 rounded-lg">
            <button className="text-white text-sm cursor-pointer hover:opacity-70" onClick={handleClickClose(true)}>
              {currentLang === 'pt' ? 'Não exibir este anúncio hoje' : 'close for today!'}
            </button>
            <img
              src="/images/icons/close.svg"
              alt=""
              className="cursor-pointer w-[30px]"
              onClick={handleClickClose()}
            />
          </div>
        </div>
      </div>
    );
  }

  return null;
}
